import axios from 'axios';

const getIsTelRestricted = (storage) => {
  return storage.isTelRestricted === 'true';
};

const checkIsTelRestricted = async (storage) => {
  let url = 'https://backend.calldoctor.jp/api/v1/consultation_time/tel';
  // STG
  if (process.env.NODE_ENV !== 'production') {
    url = 'https://stg.backend.calldoctor.jp/api/v1/consultation_time/tel';
  }
  await axios
    .get(url, {})
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.restricted !== null
      ) {
        const isTelRestricted = response.data.response.restricted;
        storage.setItem('isTelRestricted', isTelRestricted);
      }
    })
    .catch(() => {});
};

export default (_, inject) => {
  inject('getIsTelRestricted', function () {
    return getIsTelRestricted(localStorage);
  });
  inject('checkIsTelRestricted', function () {
    return checkIsTelRestricted(localStorage);
  });
};
