import { AsYouType } from 'libphonenumber-js/max';

export default {
  validate: (value) => {
    const asYouType = new AsYouType('JP');
    if (asYouType) {
      asYouType.input(value);
      return asYouType.isValid();
    }
    return false;
  },
  message: '※電話番号が正しくありません。',
};
