//
//
//
//
//
//

import { _DESCRIPTION_GLOBAL, _SERVICE_NAME, _TITLE_TEMPLATE } from '@/constants';

export default {
  head: {
    titleTemplate: _TITLE_TEMPLATE,
    meta: [
      {
        charset: 'utf-8',
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
      },
      {
        hid: 'description',
        name: 'description',
        content: _DESCRIPTION_GLOBAL,
      },
      {
        hid: 'keywords',
        name: 'keywords',
        content: `${_SERVICE_NAME},保険適用,夜間訪問クリニック,往診,オンライン診療,内科,小児科,外科,整形外科`,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: process.env.ORIGIN,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: _SERVICE_NAME,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: '保険適用の夜間訪問クリニック',
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: `${process.env.ORIGIN}/ogp.png`,
      },
      {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: 'ja_JP',
      },
    ],
    link: [
      {
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico',
      },
      {
        hid: 'preconnect-backend.calldoctor.jp',
        rel: 'preconnect',
        href: '//backend.calldoctor.jp',
      },
      {
        hid: 'preconnect-www.google-analytics.com',
        rel: 'preconnect',
        href: '//www.google-analytics.com',
      },
      {
        hid: 'preconnect-www.googletagmanager.com',
        rel: 'preconnect',
        href: '//www.googletagmanager.com',
      },
      {
        hid: 'preconnect-fonts.googleapis.com',
        rel: 'preconnect',
        href: '//fonts.googleapis.com',
      },
      {
        hid: 'preconnect-fonts.gstatic.com',
        rel: 'preconnect',
        href: '//fonts.gstatic.com',
      },
      {
        hid: 'preconnect-www.gstatic.com',
        rel: 'preconnect',
        href: '//www.gstatic.com',
      },
      {
        hid: 'preconnect-googleads.g.doubleclick.net',
        rel: 'preconnect',
        href: '//googleads.g.doubleclick.net',
      },
      {
        hid: 'preconnect-www.googleadservices.com',
        rel: 'preconnect',
        href: '//www.googleadservices.com',
      },
      {
        hid: 'preconnect-stats.g.doubleclick.net',
        rel: 'preconnect',
        href: '//stats.g.doubleclick.net',
      },
      {
        hid: 'preconnect-connect.facebook.net',
        rel: 'preconnect',
        href: '//connect.facebook.net',
      },
      {
        hid: 'preconnect-s.yimg.jp',
        rel: 'preconnect',
        href: '//s.yimg.jp',
      },
      {
        hid: 'preconnect-b97.yahoo.co.jp',
        rel: 'preconnect',
        href: '//b97.yahoo.co.jp',
      },
      {
        hid: 'dns-prefetch-backend.calldoctor.jp',
        rel: 'dns-prefetch',
        href: '//backend.calldoctor.jp',
      },
      {
        hid: 'dns-prefetch-www.google-analytics.com',
        rel: 'dns-prefetch',
        href: '//www.google-analytics.com',
      },
      {
        hid: 'dns-prefetch-www.googletagmanager.com',
        rel: 'dns-prefetch',
        href: '//www.googletagmanager.com',
      },
      {
        hid: 'dns-prefetch-fonts.googleapis.com',
        rel: 'dns-prefetch',
        href: '//fonts.googleapis.com',
      },
      {
        hid: 'dns-prefetch-fonts.gstatic.com',
        rel: 'dns-prefetch',
        href: '//fonts.gstatic.com',
      },
      {
        hid: 'dns-prefetch-www.gstatic.com',
        rel: 'dns-prefetch',
        href: '//www.gstatic.com',
      },
      {
        hid: 'dns-prefetch-googleads.g.doubleclick.net',
        rel: 'dns-prefetch',
        href: '//googleads.g.doubleclick.net',
      },
      {
        hid: 'dns-prefetch-www.googleadservices.com',
        rel: 'dns-prefetch',
        href: '//www.googleadservices.com',
      },
      {
        hid: 'dns-prefetch-stats.g.doubleclick.net',
        rel: 'dns-prefetch',
        href: '//stats.g.doubleclick.net',
      },
      {
        hid: 'dns-prefetch-connect.facebook.net',
        rel: 'dns-prefetch',
        href: '//connect.facebook.net',
      },
      {
        hid: 'dns-prefetch-s.yimg.jp',
        rel: 'dns-prefetch',
        href: '//s.yimg.jp',
      },
      {
        hid: 'dns-prefetch-b97.yahoo.co.jp',
        rel: 'dns-prefetch',
        href: '//b97.yahoo.co.jp',
      },
    ],
  },
};
