export const _CALLDOCTOR = 'コールドクター';
export const _SERVICE_NAME = 'みてねコールドクター';
export const _TITLE_GLOBAL = '夜間休日の自宅往診サービス「みてねコールドクター」';
export const _TITLE_GLOBAL_SHORT = '自宅往診サービス「みてねコールドクター」';
export const _TITLE_TEMPLATE = `%s | ${_TITLE_GLOBAL}`;
export const _TITLE_TEMPLATE_SHORT = `%s | ${_TITLE_GLOBAL_SHORT}`;

export const _TITLE_COMPANY_INTRODUCTION = '会社概要';
export const _TITLE_COMPANY_PROFILE = '運営会社';
export const _TITLE_CONTACT_US = 'お問い合わせ';
export const _TITLE_COOPERATED_CLINIC = '提携医療機関';
export const _TITLE_FLOW = '診療の流れ';
export const _TITLE_FORM = '予約お申し込み';
export const _TITLE_HOSPITALS = '病院紹介';
export const _TITLE_INSPECTION_TREAT = '可能な診察・検査';
export const _TITLE_MEDIA_REQUEST = '取材のご依頼';
export const _TITLE_MEDICAL_EXAMINATION_FEE = '診察料金';
export const _TITLE_MEDICAL_FEE = '利用料金';
export const _TITLE_PERSONAL_INFORMATION = '個人情報の取り扱いに関して';
export const _TITLE_PRIVACY_POLICY = 'プライバシーポリシー';
export const _TITLE_RECRUITING = '非常勤医師募集';
export const _TITLE_REFERRAL_HOSPITAL = '提携医療機関';
export const _TITLE_SITEMAP = 'サイトマップ';
export const _TITLE_TERMS_OF_SERVICE = '利用規約';
export const _TITLE_TRANSACTION_LAW = '特定商取引法に基づく表記';
export const _TITLE_VISIT_AREA = '往診対応エリア';
export const _TITLE_FAMILY_INFORMATION_TERMS = '家族等の登録について';

export const _DESCRIPTION_GLOBAL = `${_SERVICE_NAME}公式サイト_みてねコールドクターは夜間・休日対応の往診サービスです。` +
  '内科・小児科・外科・整形外科に対応し、電話1本で最短30分で医師がご自宅お伺いします。';

export const _PHONE_NUMBER = '0120-897-146';
export const _PHONE_NUMBER_DSELECT = '0120-897-146';
export const _PHONE_NUMBER_LOCALPLACE = '0120-438-637';
export const _ONLINE_MEDICAL_APPOINTMENT = 'WEBで診察依頼はこちら';
export const _CHOOSE_MEDICAL_TYPE_TITLE = '二つの診療タイプからお選びいただけます';
export const _CHOOSE_MEDICAL_TYPE_ONLINE = 'オンライン診療';

export const _APP_DOWNLOAD_LINK_MOBILE = 'https://calldoctor.onelink.me/Ztf1/a0d604aa';
export const _APP_DOWNLOAD_LINK_PC_APP_STORE = 'https://apps.apple.com/jp/app/id1516410571';
export const _APP_DOWNLOAD_LINK_PC_GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=jp.co.calldoctor.app&hl=ja&gl=US';
