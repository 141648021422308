import axios from 'axios';

class AcceptableTime {
  constructor (start, end) {
    this.start = start;
    this.end = end;
  }

  isAcceptable (_now) {
    if (!(this.start && this.end)) {
      return false;
    }
    const now = _now || Date.now();
    return this.start < now && now < this.end;
  }

  isReset (_now) {
    if (!this.end) {
      return true;
    }
    const now = _now || Date.now();
    return now > this.end;
  }

  static load (storage) {
    return new AcceptableTime(
      storage.acceptableTimeStart,
      storage.acceptableTimeEnd,
    );
  }

  save (storage) {
    if (!(this.start && this.end)) {
      return;
    }
    storage.acceptableTimeStart = this.start;
    storage.acceptableTimeEnd = this.end;
  }
}

const getAcceptable = async function () {
  let url = 'https://backend.calldoctor.jp/api/v1/acceptable_time';
  // STG
  if (process.env.NODE_ENV !== 'production') {
    url = 'https://stg.backend.calldoctor.jp/api/v1/acceptable_time';
  }

  let result = new AcceptableTime();
  await axios
    .get(url, {})
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.acceptable_time &&
        response.data.response.acceptable_time.start_time &&
        response.data.response.acceptable_time.end_time
      ) {
        const start = Date.parse(response.data.response.acceptable_time.start_time);
        const end = Date.parse(response.data.response.acceptable_time.end_time);
        result = new AcceptableTime(start, end);
      }
    })
    .catch(() => {});
  return result;
};

export default (_, inject) => {
  inject('isAcceptable', async function () {
    let acceptableTime = AcceptableTime.load(localStorage);

    if (!acceptableTime || acceptableTime.isReset()) {
      acceptableTime = await getAcceptable();
      acceptableTime.save(localStorage);
    }
    return acceptableTime.isAcceptable();
  });
};
